import { isString } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Stack, Transition } from '@components/common';
import { OrderPlacementNavbar } from '@components/order';
import { XenditCreateAuthResponse, XenditCreateTokenResponse } from '@interfaces/xendit';

interface Props {
  url?: string;
  onClose: () => void;
  onPaymentSuccess?: (response: XenditCreateAuthResponse | XenditCreateTokenResponse) => void;
}

const XenditCreditCardPaymentDialog: React.FC<Props> = (props) => {
  const { url, onClose, onPaymentSuccess } = props;
  const { t } = useTranslation();

  const handleReceivedMessage = useCallback(
    (e: MessageEvent) => {
      try {
        const data = isString(e?.data) ? JSON.parse(e?.data) : e?.data;
        if (data?.status === 'VERIFIED') {
          onPaymentSuccess?.(e.data as XenditCreateAuthResponse | XenditCreateTokenResponse);
          onClose();
        }
      } catch ({ message = t('error.general_error_label') }) {
        console.error({ message });
      }
    },
    [onClose, onPaymentSuccess, t]
  );

  useEffect(() => {
    window.addEventListener('message', handleReceivedMessage);

    return () => {
      window.removeEventListener('message', handleReceivedMessage);
    };
  }, [handleReceivedMessage]);

  const open = !!url;
  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <OrderPlacementNavbar onPressBack={onClose} />
      <Stack height={'100%'} padding={'m'}>
        {url && <iframe height={'100%'} width={'100%'} frameBorder={'0'} src={url} />}
      </Stack>
    </Dialog>
  );
};

export default XenditCreditCardPaymentDialog;
