import { useForm as useFormReactHook } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export interface FormProps {
  cvn: string;
}

export const CVN_FORM_DEFAULT_VALUES = {
  cvn: '',
};

const useForm = () => {
  const schema = yup.object().shape({
    cvn: yup.string().required(),
  });

  return useFormReactHook<FormProps>({
    defaultValues: CVN_FORM_DEFAULT_VALUES,
    resolver: yupResolver(schema),
  });
};

export default useForm;
