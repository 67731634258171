import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from '@api/order/types';
import { Button, Dialog, Image, Stack, Transition, Typography } from '@components/common';
import Token from '@constants/token';
import { useOnlineDeliveryPaymentMethodOptions } from '@hooks/orders';
import { Divider } from '@mui/material';
import { useQueryBrandInfo } from '@queries/commons';
import OrderExpirationTimer from '../OrderExpirationTimer';

interface Props {
  open: boolean;
  onClose: () => void;
  paymentMethod: PaymentMethod;
  checkoutUrl?: string;
  expiredAt?: Date;
  onCancelPayment?: () => void;
}

const CheckoutFormDialog = ({ open, checkoutUrl, onClose, paymentMethod, onCancelPayment, expiredAt }: Props) => {
  const { t } = useTranslation();
  const { data } = useQueryBrandInfo();

  const brandLogo = data?.brand?.logoUrl;
  const brandName = data?.brand?.name;

  const paymentMethodOptions = useOnlineDeliveryPaymentMethodOptions();
  const selectedPaymentMethod = paymentMethodOptions.find((option) => option.type === paymentMethod.type);

  const openCheckoutPage = () => {
    window.open(checkoutUrl, '_blank', 'noopener,noreferrer');
  };

  const handleClose = useCallback(() => {
    onCancelPayment?.();
    onClose();
  }, [onCancelPayment, onClose]);

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <Stack height={'100%'} padding={'m'} justifyContent={'space-between'}>
        <Stack direction={'row'} justifyContent={'flex-end'}>
          <Image
            height={30}
            width={30}
            src={brandLogo}
            alt={t('general.alt_logo_image', { brandName })}
            objectFit={'contain'}
          />
        </Stack>
        <OrderExpirationTimer startTimer={open} expiredAt={expiredAt} onTimerExpired={handleClose} />

        <Stack spacing={'xxl'}>
          <Stack spacing={'m'}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Stack direction={'row'} spacing={'m'} alignItems={'center'}>
                {selectedPaymentMethod?.icon}
                <Typography>{selectedPaymentMethod?.label}</Typography>
              </Stack>
              {checkoutUrl && (
                <Stack
                  color={Token.color.uiDarkSecondary}
                  borderColor={'uiLightSecondary'}
                  borderWidth={'bolder'}
                  borderRadius={'default'}
                >
                  <Button variant={'contained'} color={'primary'} onClick={openCheckoutPage} borderRadius={'default'}>
                    <Typography size={'xs'}>{t('order.open_app_label')}</Typography>
                  </Button>
                </Stack>
              )}
            </Stack>
            <Divider />
            <Typography size={'hs'}>{t('order.waiting_for_payment_label')}</Typography>
          </Stack>

          <Stack spacing={'xs'}>
            <Button color={'secondary'} variant={'text'} onClick={handleClose}>
              <Typography color={'uiDarkSecondary'} textDecoration={'underline'}>
                {t('order.change_payment_method_label')}
              </Typography>
            </Button>
            <Button color={'secondary'} variant={'text'} onClick={handleClose}>
              <Typography color={'uiDarkSecondary'} textDecoration={'underline'}>
                {t('order.cancel_payment_label')}
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default CheckoutFormDialog;
