import { SeoTagsReturnProps } from '@hooks/common/useMetaTags';

/**
 * NOTE: Why use function instead of component?
 * Somehow when we are wrapping tags for next/head with a component and fragment, NextJS will stripped those tags away.
 * NextJS will also remove the value of title if we don't set it directly under next/head OR use this function.
 * This is a known bug from NextJS: https://github.com/vercel/next.js/issues/3527
 */
const buildTags = ({ title, metaTags = [], linkTags = [] }: SeoTagsReturnProps) => {
  return (
    <>
      <title>{title}</title>
      {metaTags.map((metaTag) => (
        <meta key={metaTag.name} {...metaTag} />
      ))}
      {linkTags.map((linkTag) => (
        <link key={linkTag.rel} {...linkTag} />
      ))}
    </>
  );
};

export default buildTags;
