import { GetServerSideProps } from 'next';
import nookies from 'nookies';
import { QueryClient, dehydrate } from 'react-query';
import { getServerBrandInfo } from '@api/auth';
import { defaultQueryOption } from '@api/baseAPI';
import { PREVIEW_DEV_DOMAIN } from '@constants/auth';
import { QUERY_KEY_SERVER_GET_BRAND_INFO } from '@constants/queryKey';
import { GetBrandInfoQueryServerKey } from '@queries/commons/useQueryServerBrandInfo';

const getServerSideProps: GetServerSideProps = async (context) => {
  const { req } = context;
  const queryClient = new QueryClient(defaultQueryOption);

  let brandUrl = req.headers['host'] ?? '';

  if (process.env.NEXT_PUBLIC_ENABLE_PREVIEW_DEV_DOMAIN === 'true') {
    const cookies = nookies.get(context);
    const previewDevDomain = cookies[PREVIEW_DEV_DOMAIN];
    const fallBackUrl = process.env.NEXT_PUBLIC_FALLBACK_PREVIEW_DEV_DOMAIN;

    if (previewDevDomain) {
      brandUrl = previewDevDomain;
    }

    if (fallBackUrl) {
      brandUrl = fallBackUrl;
    }
  }

  const queryKeys: GetBrandInfoQueryServerKey[] = [
    {
      key: QUERY_KEY_SERVER_GET_BRAND_INFO,
      payload: {
        brandUrl,
      },
    },
  ];

  await queryClient.prefetchQuery(queryKeys, getServerBrandInfo({ brandUrl }));

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      brandUrl,
    },
  };
};

export default getServerSideProps;
