import { differenceInMinutes, differenceInSeconds, isFuture } from 'date-fns';
import Decimal from 'decimal.js';
import { useEffect, useMemo, useState } from 'react';
import { Stack, Typography } from '@components/common';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { calcDecimal } from '@utils/number';

const DEFAULT_VALUE_DENOMINATOR = 15;

interface CircularProgressWithLabelProps extends CircularProgressProps {
  timer: string;
}
const CircularProgressWithLabel = (props: CircularProgressWithLabelProps) => {
  return (
    <Stack position={'relative'} alignItems={'center'}>
      <CircularProgress variant={'determinate'} {...props} />
      <Stack position={'absolute'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
        <Typography size={'xxl'}>{props.timer}</Typography>
      </Stack>
    </Stack>
  );
};

interface Props {
  expiredAt?: Date;
  startTimer: boolean;
  onTimerExpired: () => void;
}
const OrderExpirationTimer = ({ expiredAt, startTimer, onTimerExpired }: Props) => {
  const [timeLeft, setTimeLeft] = useState({
    seconds: 0,
    minutes: 0,
  });

  useEffect(() => {
    if ((startTimer || !startTimer) && expiredAt && isFuture(expiredAt)) {
      const minutes = differenceInMinutes(expiredAt, new Date());
      const seconds = differenceInSeconds(expiredAt, new Date()) % 60;
      setTimeLeft({ seconds, minutes });
    }
  }, [expiredAt, startTimer]);

  useEffect(() => {
    const updateTimer = () => {
      if (startTimer && expiredAt && isFuture(expiredAt)) {
        const minutes = differenceInMinutes(expiredAt, new Date());
        const seconds = differenceInSeconds(expiredAt, new Date()) % 60;
        setTimeLeft({ seconds, minutes });
      }
    };

    const timer = setInterval(() => {
      if (startTimer && timeLeft.minutes === 0 && timeLeft.seconds === 0) {
        onTimerExpired();
        clearInterval(timer);
        return;
      }

      updateTimer();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [expiredAt, onTimerExpired, startTimer, timeLeft.minutes, timeLeft.seconds]);

  const timerString = `${String(timeLeft.minutes)?.padStart(2, '0')}:${String(timeLeft.seconds)?.padStart(2, '0')}`;

  const value = useMemo(() => {
    const { seconds, minutes } = timeLeft;
    const denominatorInMinutes = minutes > 15 ? minutes + DEFAULT_VALUE_DENOMINATOR : DEFAULT_VALUE_DENOMINATOR;

    const denominator = denominatorInMinutes * 60;

    const numerator = seconds + minutes * 60;
    const parsedNumerator = calcDecimal(numerator);

    if (parsedNumerator instanceof Decimal) {
      return parsedNumerator.div(denominator).mul(100).toNumber();
    }

    return 0;
  }, [timeLeft]);

  return <CircularProgressWithLabel value={value} timer={timerString} size={120} />;
};

export default OrderExpirationTimer;
