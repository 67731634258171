import { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Stack, Typography } from '@components/common';
import RHFTextFieldMask from '@components/common/RHFTextFieldMask';
import useForm, { CVN_FORM_DEFAULT_VALUES, FormProps } from './forms/useForm';

interface Props {
  onSubmit: SubmitHandler<FormProps>;
  isLoading?: boolean;
  open: boolean;
  handleClose: () => void;
}

const CreditCardCvnFormDialog = ({ onSubmit, isLoading, open, handleClose }: Props) => {
  const { t } = useTranslation();
  const { control, watch, handleSubmit, reset } = useForm();

  useEffect(() => {
    if (open) {
      reset(CVN_FORM_DEFAULT_VALUES);
    }
  }, [open, reset]);

  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <Stack padding={'m'} spacing={'xxl'}>
        <RHFTextFieldMask
          name={'cvn'}
          control={control}
          label={t('order.credit_card_cvn_label')}
          subLabel={t('order.credit_card_cvn_description')}
          mask={'999'}
          inputProps={{
            type: 'password',
            inputProps: {
              inputMode: 'numeric',
            },
            autoComplete: 'off',
          }}
        />
        <Button
          disabled={watch('cvn')?.length < 3}
          onClick={handleSubmit(onSubmit)}
          variant={'contained'}
          padding={'m'}
          isLoading={isLoading}
        >
          <Typography size={'hm'} variant={'medium'}>
            {t('order.delivery_address_name_input_button_label')}
          </Typography>
        </Button>
      </Stack>
    </Dialog>
  );
};

export default CreditCardCvnFormDialog;
