import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DecimalNumber } from '@api/baseAPI/types';
import { VirtualAccountPaymentMethod } from '@api/order/types';
import { Button, Dialog, Image, Stack, Transition, Typography } from '@components/common';
import Token from '@constants/token';
import { Divider } from '@mui/material';
import { useQueryBrandInfo } from '@queries/commons';
import { getXenditPaymentMethodIcon } from '@utils/payment';
import OrderExpirationTimer from '../OrderExpirationTimer';

interface Props {
  onClose: () => void;
  virtualAccountNumber: string;
  paymentMethod?: VirtualAccountPaymentMethod;
  expiredAt?: Date;
  onCancelPayment?: () => void;
  totalPrice: DecimalNumber;
}

const VirtualAccountDialog = ({
  totalPrice,
  onClose,
  virtualAccountNumber,
  paymentMethod,
  onCancelPayment,
  expiredAt,
}: Props) => {
  const open = !!virtualAccountNumber;

  const { t } = useTranslation();
  const { data } = useQueryBrandInfo();

  const brandLogo = data?.brand?.logoUrl;
  const brandName = data?.brand?.name;
  const paymentMethodIcon = getXenditPaymentMethodIcon(paymentMethod);

  const copyVaNumber = () => navigator.clipboard.writeText(virtualAccountNumber);

  const handleClose = useCallback(() => {
    onCancelPayment?.();
    onClose();
  }, [onCancelPayment, onClose]);

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <Stack height={'100%'} padding={'m'} justifyContent={'space-between'}>
        <Stack direction={'row'} justifyContent={'flex-end'}>
          <Image
            height={30}
            width={30}
            src={brandLogo}
            alt={t('general.alt_logo_image', { brandName })}
            objectFit={'contain'}
          />
        </Stack>
        <Stack spacing={'xxl'}>
          <OrderExpirationTimer startTimer={open} expiredAt={expiredAt} onTimerExpired={handleClose} />
          <Typography textAlign={'center'} size={'m'} variant={'medium'} color={'uiRedPrimary'}>
            {totalPrice}
          </Typography>
        </Stack>
        <Stack spacing={'xxl'}>
          <Stack spacing={'m'}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Stack direction={'row'} spacing={'m'} alignItems={'center'}>
                {paymentMethodIcon}
                <Typography textAlign={'center'} variant={'bold'} size={'s'}>
                  {virtualAccountNumber}
                </Typography>
              </Stack>
              <Stack
                color={Token.color.uiDarkSecondary}
                borderColor={'uiLightSecondary'}
                borderWidth={'bolder'}
                borderRadius={'default'}
              >
                <Button variant={'contained'} color={'primary'} onClick={copyVaNumber} borderRadius={'default'}>
                  <Typography size={'xs'}>{t('order.copy_va_number_label')}</Typography>
                </Button>
              </Stack>
            </Stack>
            <Divider />
          </Stack>

          <Stack>
            {t('order.virtual_account_form_description', { virtualAccountId: paymentMethod?.bankId })
              ?.split('|')
              .map?.((instruction, index) => (
                <Typography size={'xs'} key={index}>{`${index + 1}. ${instruction}`}</Typography>
              ))}
          </Stack>
          <Stack spacing={'xs'}>
            <Button color={'secondary'} variant={'text'} onClick={handleClose}>
              <Typography color={'uiDarkSecondary'} textDecoration={'underline'}>
                {t('order.change_payment_method_label')}
              </Typography>
            </Button>
            <Button color={'secondary'} variant={'text'} onClick={handleClose}>
              <Typography color={'uiDarkSecondary'} textDecoration={'underline'}>
                {t('order.cancel_payment_label')}
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default VirtualAccountDialog;
